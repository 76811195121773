<template>
  <div class="subscribe-wrap">
    <div class="subscribe-box" v-for="item in detailList" :key="item.id" @click="subcribeHandle(item)">
      <div class="box-header">
        <div class="main-title">{{item.type | filterType}}</div>
        <div class="box-tool">
          <span :style="{color:item.isSub=='0'?'#ee0a24':'#1989fa'}">{{item.isSub=='1'?'已订阅':"未订阅"}}</span>
        </div>
      </div>
      <div class="box-main">
        <div class="main-info">
          <div class="info-title time">接受时间：</div>
          <div class="info-detail time">
            <span v-for="items in item.timeList" :key="items">{{items}}</span>
          </div>
        </div>
        <div class="main-info">
          <div class="info-title device">订阅设备：</div>
          <div class="info-detail device">
            <span v-if="item.type=='1'||item.type=='2'">{{item.deviceIds=='0'?'全部（默认）':item.deviceNum}}</span>
            <span v-else>{{item.deviceIds=='0'?'全部':item.deviceNum}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import { getWechatSubConfig } from "@/api/subscribe"
export default {
  name: "Subscribe",
  components: {
  },
  filters: {
    filterType(val) {
      let result = ''
      switch (val) {
        case 1: result = '工单提醒';
          break;
        case 2: result = '工单审批';
          break;
        case 3: result = '设备告警';
          break;
        case 4: result = '工艺告警';
          break;
      }
      return result
    }
  },

  created() {
    this.getWechatSubConfig()
  },
  data() {
    return {
      detailList: []
    }
  },

  methods: {
    getWechatSubConfig() {
      getWechatSubConfig().then(res => {
        this.detailList = res
      })
    },
    subcribeHandle(item) {
      let param = {
        type: item.type,
        deviceIds: item.deviceIds ? (item.deviceIds).split(',') : [],
        timeList: item.deviceIds ? item.timeList : [],
      }
      this.$router.push({
        path: '/subscribeItem',
        query: { info: JSON.stringify(param) }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../style/mixin';
.subscribe-wrap {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  .subscribe-box {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    width: 100%;
    background: #fff;
    margin-bottom: 0.5rem;
    .box-header {
      @include fd();
      border-bottom: 1px solid #ebedf0;
      position: relative;
      padding-bottom: 0.2rem;
      align-items: center;

      .main-title {
        position: relative;
        font-size: 0.8rem;
        font-weight: bold;
        flex: 1;
      }

      .main-title::before {
        position: absolute;
        top: 50%;
        left: -0.75rem;
        width: 0.15rem;
        height: 0.8rem;
        background-color: #1989fa;
        transform: translateY(-50%);
        content: '';
      }
      .box-tool {
        display: inline-flex;
        span {
          color: #1989fa;
          font-size: 0.8rem;
          font-weight: bold;
          vertical-align: text-top;
        }
        .active {
          color: #fff;
          background: #1989fa;
          padding: 0.2rem 0.3rem;
          border-radius: 10%;
          font-size: 0.7rem;
          vertical-align: inherit;
        }
      }
    }
    .box-main {
      .main-info {
        width: 100%;
        @include fd();
        padding-top: 0.5rem;
        .info-title {
          font-size: 0.7rem;
          font-weight: bold;
        }

        .info-detail {
          width: 0;
          flex: 1;
          span {
            display: block;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
          }
        }
        .time {
          color: #666;
        }
        .device {
          color: #999;
          span {
            display: block;
            font-size: 0.7rem;
            font-weight: bold;
            color: #999;
          }
        }
      }
    }
  }
}
</style>
